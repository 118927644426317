import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from 'components/ui-components-v2/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ListItemText from '@mui/material/ListItemText';
import ListItem from 'components/ui-components-v2/ListItem';
import List from 'components/ui-components-v2/List';
import Divider from 'components/ui-components-v2/Divider';
import { withResources } from 'components/data/Resources';
import EditorData from 'components/editor-data/EditorData';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

/**
 * SelectDialog
 * The dialog for selecting from a list of text options.
 * The options can be managed if you have the rights to do so.
 */
export class SelectDialog extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            selectDialogOpen: false
        };
    }

    /**
     * Selecting an individual item
     */
    handleSelect = (item) => {
        const languageDictionary = this.props.languageDictionary;
        const newValue = {};

        const itemData = languageDictionary[item];
        Object.keys(itemData).forEach((key) => {
            if (key.length === 5) {
                if (key.substring(0, 2) === EditorData.get('market')) {
                    const language = key.substring(3, 2);
                    newValue[language] = {
                        value: itemData[key]
                    };
                }
            } else {
                const language = key;
                newValue[language] = {
                    value: itemData[key]
                };
            }
        });

        this.props.onChange(newValue);
    };

    render() {
        const { onClose, languageDictionary } = this.props;

        return (
            <Dialog open={true} TransitionComponent={Transition} onClose={onClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1 }}>{'Select preset copy'}</div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <React.Fragment>
                            <List>
                                {Object.keys(languageDictionary).map((item, i) => (
                                    <React.Fragment key={i}>
                                        <ListItem onClick={() => this.handleSelect(item)} key={item}>
                                            <ListItemText primary={languageDictionary[item].EN} />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        </React.Fragment>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
export default withResources(SelectDialog, ['languageDictionary']);
