const getCssColor = (color) => {
    if (color.type === undefined && color.hex) {
        return color.hex;
    }

    if (color.type === 'solid') {
        return color?.points?.[0]?.color?.hex || color.hex;
    }

    if (color.type === 'linear') {
        let points = '';
        if (color?.points?.map) {
            points = color.points.map((point) => {
                const { r, g, b, a } = point.color.rgb;
                return `rgba(${r},${g},${b},${a}) ${point.location * 100}%`;
            });
        }
        return `linear-gradient(${color.rotation || 0}deg, ${points.toString()})`;
    }

    if (color.type === 'radial') {
        let points = '';
        if (color?.points?.map) {
            points = color.points.m;
            points = color.points.map((point) => {
                const { r, g, b, a } = point.color.rgb;
                return `rgba(${r},${g},${b},${a}) ${point.location * 100}%`;
            });
        }
        return `radial-gradient(${points.toString()})`;
    }

    return 'transparent';
};

export default getCssColor;
