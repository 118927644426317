import { FormControlLabel, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEqual } from 'lodash';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Chip from 'components/ui-components-v2/Chip';

/**
 * Checklist input field
 * This shows a list of checkboxes
 */
export class CheckList extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Options to add to the checklist */
        options: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
            PropTypes.objectOf(PropTypes.string)
        ]),
        maximumItems: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        readOnly: PropTypes.bool
    };

    static defaultProps = {
        value: {
            value: [],
            updated: false
        },
        readOnly: false,
        options: [],
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        let options = props.options;
        if (!(props.options instanceof Array)) {
            options = Object.keys(options).map((x) => ({ key: x, value: options[x] }));
        }

        this.state = {
            value: props.value.value ? props.value.value : [],
            options: options
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!isEqual(this.props.value.value, prevProps.value.value)) {
            this.setState({ value: this.props.value.value });
        }
        if (!isEqual(this.props.options, prevProps.options)) {
            let options = this.props.options;
            if (!(this.props.options instanceof Array)) {
                options = Object.keys(options).map((x) => ({ key: x, value: options[x] }));
            }
            this.setState({ options });
        }
    };

    /**
     * A checkbox was checked, change state
     */
    handleValueChanged = (event) => {
        const { onMutation, maximumItems } = this.props;

        if (event.target.checked && maximumItems && this.state.value.length >= maximumItems) {
            return;
        }

        let newValue = null;
        if (event.target.checked) {
            newValue = [...this.state.value, event.target.name];
        } else {
            newValue = this.state.value.filter((x) => x !== event.target.name);
        }
        this.setState({ value: newValue });

        onMutation({
            value: newValue,
            updated: true
        });
    };

    render() {
        const { value, options } = this.state;
        const { readOnly } = this.props;

        if (readOnly) {
            return <div>{options.map((x) => value.includes && value.includes(x.key) && <Chip key={x.key} label={x.value} style={{ marginRight: 5 }} />)}</div>;
        } else {
            return (
                <FormGroup value={value} onChange={this.handleValueChanged}>
                    {options.map((x, index) => (
                        <FormControlLabel
                            key={x.key + '-' + index}
                            control={<Checkbox name={x.key} checked={value.includes && value.includes(x.key)} />}
                            label={x.value}
                        />
                    ))}
                </FormGroup>
            );
        }
    }
}

export default CheckList;
