import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import getColorPickerObject from '../utils/getColorPickerObject';
import getCssColor from '../utils/getCssColor';
import '../styles/main.scss';

export class ColorSelectorPredefinedCustom extends Component {
    static propTypes = {
        /** Array of colors to display as circles */
        colors: PropTypes.arrayOf(PropTypes.string),
        /** Function which gets called when the value has changed */
        onChange: PropTypes.func,
        /** Current value of this selector */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    };

    static defaultProps = {
        colors: [],
        onChange: () => { },
        value: ''
    };

    render() {
        const { colors, onChange, value } = this.props;

        return (
            <div className="color-selector__custom">
                {colors.map((color, index) => {
                    const correctColor = (() => {
                        if (typeof color === 'string') {
                            return getColorPickerObject(color);
                        } else if (typeof color.hex === 'string') {
                            return getColorPickerObject(color.hex);
                        }

                        return color;
                    })();

                    const selected = (() => {
                        if (correctColor.type === 'transparent' && value.type === 'transparent') {
                            return true;
                        }
                        const sameType = correctColor.type === value.type;
                        const sameRotation = parseInt(correctColor.rotation) === parseInt(value.rotation);
                        const samePoints = correctColor.points.length === value.points.length;
                        const sameColor = (() => {
                            if (!correctColor?.points?.[0]?.color?.rgb || !value?.points?.[0]?.color?.rgb) return false;

                            return (
                                correctColor.points[0].color.rgb.r === value.points[0].color.rgb.r &&
                                correctColor.points[0].color.rgb.g === value.points[0].color.rgb.g &&
                                correctColor.points[0].color.rgb.b === value.points[0].color.rgb.b &&
                                correctColor.points[0].color.rgb.a === value.points[0].color.rgb.a
                            );
                        })();

                        return sameType && sameRotation && samePoints && sameColor;
                    })();

                    return (
                        <div
                            key={index}
                            onClick={() => onChange(correctColor)}
                            className={classNames('color-selector__custom__color-container', {
                                'color-selector__custom__color-container--selected': selected
                            })}>
                            <div className="color-selector__custom__color" style={{ background: getCssColor(correctColor) }} />
                            <div className="color-selector__custom__grid" />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ColorSelectorPredefinedCustom;
