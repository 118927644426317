import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CirclePicker } from 'react-color';

export class ColorSelectorPredefined extends Component {
    static propTypes = {
        /** Array of colors to display as circles */
        colors: PropTypes.arrayOf(PropTypes.string),
        /** Function which gets called when the value has changed */
        onChange: PropTypes.func,
        /** Current value of this selector */
        value: PropTypes.string
    };

    static defaultProps = {
        colors: [],
        onChange: (color, event) => {},
        value: ''
    };

    render() {
        const { colors, onChange, value } = this.props;

        return <CirclePicker colors={colors} onChange={onChange} color={value} />;
    }
}

export default ColorSelectorPredefined;
