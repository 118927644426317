import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineAlert from 'components/ui-components/InlineAlert';

/**
 * AlertBox
 * This allows you to show an alertbox as an input
 * This is not an actual input item, but can be useful to conditionally show in between the input boxes as a warning/info box.
 */
export default class AlertBox extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        message: PropTypes.string,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        alertType: PropTypes.string
    };

    static defaultProps = {
        message: '',
        alertType: 'info',
        onMutation: () => {}
    };

    render() {
        const { alertType, message, data } = this.props;
        const messageDisplay = message ? message : data.message;
        const type = data && data.alertType ? data.alertType : alertType;

        return (
            <InlineAlert type={type} inputKey={data ? data.inputKey : ''}>
                {' '}
                <div dangerouslySetInnerHTML={{ __html: messageDisplay }} />
            </InlineAlert>
        );
    }
}
