import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import { TemplateType } from 'types/templates.type';
import { FileType } from 'components/bricks/types/brick.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import CreativeBuilderTemplateDialog from 'components/creative-builder/CreativeBuilderTemplateDialog';
import Setup from 'components/data/Setup';
import {
    CreativeV2,
    CreativeV2Enriched,
    CreativeV2Template,
    CreativeV2TemplateEnriched
} from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeEditorV2 } from 'components/creatives-v2/components/creative-editor';
import { isCustomUploadCreative, isMediaCreative, isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import AssetGalleryDialogProps from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogProps';
import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import { isAMV2Enabled } from 'components/template-management/utilities';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import { SingleCreativeSelector } from './single-selector';
import { MultiCreativeSelector } from './multi-selector';
import { createCreativeBasedOnCampaignAsset, createCustomUploadCreative, createTemplateCreative } from '../utils/create-creative';

import '../styles/main.scss';

interface Props {
    value?: CreativeV2[];
    templateTypes: TemplateType[];
    multiple?: boolean;
    onMutation: (creatives: CreativeV2[]) => void;
    isButton?: boolean;
    customButton?: React.ReactNode;
    selectors?: AssetGalleryDialogSelectorType[];
    fileType?: FileType | FileType[];
    canEditCreative?: boolean;
}

const CreativeSelector = ({
    value,
    templateTypes = ['displayAdDesigned'],
    selectors = ['template'],
    fileType = ['image', 'video'],
    multiple = false,
    isButton = false,
    customButton,
    canEditCreative = true,
    onMutation
}: Props) => {
    const [creativeEditorOpen, setCreativeEditorOpen] = useState<boolean>(false);
    const [activeCreative, setActiveCreative] = useState<CreativeV2Template | CreativeV2TemplateEnriched | undefined>();
    const [assetGalleryDialogOpen, setAssetGalleryDialogOpen] = useState<boolean>(false);
    const [enrichedCreatives, setEnrichedCreatives] = useState<CreativeV2Enriched[]>([]);
    // TODO: When template management is done we can remove this
    const assetGalleryDialogProps: AssetGalleryDialogProps = {
        selectors, // Enable selectors for the AssetGalleryDialog. //  add 'upload', 'contentSpace'
        open: true, // This is being overwritten by TemplateDialog.
        fileType, // Filetype is set to handle images and videos.
        canUseContentSpace: true, // Enables the content space selector.
        onMutation: (data: any, dataType?: undefined | 'sourceData') => handleOnMutationAssetGallery(data, dataType), // Handle Onutation for the AssetGalleryDialog.
        onClose: () => null // This is being overwritten by TemplateDialog.
    };

    useEffect(() => {
        const enrichCreatives = async () => {
            if (!value) return;

            const enrichedCreatives = await Promise.all(
                value.map(async (creative) => {
                    return await CreativeV2Helpers.enrichCreative(creative);
                })
            );

            setEnrichedCreatives(enrichedCreatives);
        };

        enrichCreatives();
    }, [value]);

    /**
     * Update the creatives array based on changes from the single- and multiselector (e.g. after a user has updated or removed a creative
     * @param updatedCreatives The updated creatives array
     */
    const updateCreatives = (updatedCreatives: CreativeV2Enriched[]) => {
        setEnrichedCreatives(updatedCreatives);

        const prevCreativesIds = value?.map((item) => item.id) || [];
        const newCreativesIds = updatedCreatives.map((item) => item.id);

        if (newCreativesIds.length === 0) {
            onMutation([]);
            return;
        }

        if (isEqual(prevCreativesIds, newCreativesIds)) return;

        // Go over the new creatives array and push the unenriched creatives to redux
        const cleanedCreatives: CreativeV2[] = updatedCreatives
            .map((creative) => {
                if (isTemplateCreative(creative)) {
                    return {
                        id: creative.id,
                        title: creative.title,
                        type: 'template',
                        assetManagerId: creative['assetManagerId']
                    };
                } else if (isMediaCreative(creative)) {
                    return {
                        id: creative.id,
                        title: creative.title,
                        type: 'media',
                        assetManagerId: creative['assetManagerId']
                    };
                } else if (isCustomUploadCreative(creative)) {
                    return {
                        id: creative.id,
                        title: creative.title,
                        type: 'customUpload',
                        data: creative.data
                    };
                } else return null;
            })
            .filter((creative): creative is CreativeV2 => !!creative);

        onMutation(cleanedCreatives);
    };

    const editCreative = (creative: CreativeV2 | CreativeV2Enriched) => {
        if (isTemplateCreative(creative)) {
            setActiveCreative(cloneDeep(creative));
            setCreativeEditorOpen(true);
        }
    };

    const addCreative = async (creative: CreativeV2, preventEdit = false) => {
        if (!value) value = [];

        if (multiple) {
            onMutation([...value, creative]);
        } else {
            if (value.length > 0) {
                const deleteCreative = value[0];
                if (isTemplateCreative(deleteCreative)) {
                    await AssetManagementService.deleteAsset(deleteCreative.assetManagerId, true);
                }
            }

            onMutation([creative]);
        }

        setAssetGalleryDialogOpen(false);

        if (canEditCreative && isTemplateCreative(creative) && !preventEdit) editCreative(creative);
    };

    // We can handle both custom uploads and campaign assets here. If we choose a creative in the asset gallery via the campaign assets,
    // we will reuse this creative and assetManagerId
    const handleOnMutationAssetGallery = (customUploadData: any, dataType?: undefined | string) => {
        if (dataType === 'sourceData') return;
        if (!customUploadData) return;

        let creative: CreativeV2 | undefined;

        switch (dataType) {
            case 'campaignAssets':
                creative = createCreativeBasedOnCampaignAsset(customUploadData);
                break;
            default:
                creative = createCustomUploadCreative(customUploadData);
                break;
        }

        addCreative(creative, true);
    };

    /**
     * When a user selects an item from the template dialog, we will create a new creative based on the selected template
     * TODO: CREATIVES-v2: Only supports templates for now. We should add support for creatives and media assets as well
     * @param selectedTemplate
     */
    const onSelectTemplate = async (selectedTemplate: any) => {
        if (isAMV2Enabled()) {
            const creative = await createTemplateCreative(selectedTemplate);
            addCreative(creative, true);
            return;
        }

        if (!selectedTemplate?.identifier) return;
        // Create a new creativeV2 template creative
        const newCreativeData = await AssetManagementService.createAsset({
            type: 'creativeV2',
            title: selectedTemplate.title,
            data: {
                templateType: selectedTemplate.type,
                templateId: selectedTemplate.identifier,
                settings: {
                    frames: 1
                },
                ...(!!selectedTemplate.defaultData && { templateInput: selectedTemplate.defaultData })
            }
        });

        const creative: CreativeV2 = {
            id: uuidv4(),
            title: newCreativeData.data.title,
            type: 'template',
            thumbnail: selectedTemplate?.image,
            assetManagerId: newCreativeData.data._id
        };

        addCreative(creative);
    };

    const handleOnCloseCE = () => {
        setCreativeEditorOpen(false);
    };

    // When the data of the creative changes, we should also update that data in the creative in the creatives array
    // Otherwise we have to enrich it again via a call to the backend
    const handleSaveTemplateCreative = (updatedCreative: CreativeV2TemplateEnriched) => {
        const newEnrichedCreatives = cloneDeep(enrichedCreatives).map((creative: CreativeV2Enriched) => {
            if (isTemplateCreative(creative) && creative.assetManagerId === updatedCreative.assetManagerId) {
                return updatedCreative;
            }
            return creative;
        });

        setEnrichedCreatives(newEnrichedCreatives);
    };

    const getSingleCreativeValue = () => {
        if (Array.isArray(enrichedCreatives)) {
            return enrichedCreatives[0];
        }

        return enrichedCreatives;
    };

    const openAssetGalleryDialog = () => {
        setAssetGalleryDialogOpen(true);
    };

    const loaded = !value || enrichedCreatives.length === value?.length;

    return (
        <div className="creative-selector">
            {!multiple && (
                <SingleCreativeSelector
                    creative={getSingleCreativeValue()}
                    loaded={loaded}
                    openAssetGalleryDialog={openAssetGalleryDialog}
                    editCreative={editCreative}
                    onChange={updateCreatives}
                    isButton={isButton}
                    customButton={customButton}
                />
            )}
            {multiple && (
                <MultiCreativeSelector
                    creatives={enrichedCreatives}
                    loaded={loaded}
                    openAssetGalleryDialog={openAssetGalleryDialog}
                    editCreative={editCreative}
                    onChange={updateCreatives}
                />
            )}

            {assetGalleryDialogOpen && isAMV2Enabled() && (
                <AssetGalleryDialog
                    {...assetGalleryDialogProps}
                    title={Translation.get('formflow.JSON.addItem', 'common')}
                    onMutation={assetGalleryDialogProps?.onMutation ?? (() => null)}
                    open
                    fullWidth
                    fixedHeightPaperScrollPaper
                    onClose={() => setAssetGalleryDialogOpen(false)}
                    selectors={selectors}
                    selectorsProps={{
                        templateProps: {
                            subType: templateTypes,
                            onSelectItem: onSelectTemplate
                        }
                    }}
                />
            )}

            {assetGalleryDialogOpen && !isAMV2Enabled() && (
                <CreativeBuilderTemplateDialog
                    open
                    templateTypes={templateTypes}
                    showCreatives={Setup.hasModule('creativeManagement')}
                    onClose={() => setAssetGalleryDialogOpen(false)}
                    onSelectItem={onSelectTemplate}
                    assetGalleryDialogProps={assetGalleryDialogProps}
                />
            )}

            {creativeEditorOpen && activeCreative && (
                <CreativeEditorV2 creativeV2Template={activeCreative} onClose={handleOnCloseCE} onSaveCreative={handleSaveTemplateCreative} />
            )}
        </div>
    );
};

export default CreativeSelector;
