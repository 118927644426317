import PropTypes from 'prop-types';
import { Component } from 'react';

export class Custom extends Component {
    static propTypes = {
        component: PropTypes.object
    };

    render() {
        const { component } = this.props;
        return component;
    }
}

export default Custom;
