import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from 'components/ui-components-v2/TextField';
import Chip from 'components/ui-components-v2/Chip';

export class AutocompleteTaglist extends Component {
    static propTypes = {
        value: PropTypes.any,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        defaultValue: PropTypes.array,
        freeSolo: PropTypes.bool,
        labelData: PropTypes.object,
        disableCloseOnSelect: PropTypes.bool
    };

    static defaultProps = {
        onChange: () => {},
        placeholder: 'Enter your tag'
    };

    render() {
        const { options, defaultValue, freeSolo, placeholder, label, value, labelData = {}, disableCloseOnSelect } = this.props;

        return (
            <MuiAutocomplete
                multiple
                options={options}
                getOptionLabel={(option) => (labelData[option] ? labelData[option] : option.label ? option.label : option)}
                defaultValue={defaultValue}
                value={value ? value : []}
                disableCloseOnSelect={disableCloseOnSelect}
                onChange={(e, v) => this.props.onChange(e, v)}
                freeSolo={freeSolo}
                style={{ width: '100%' }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip size="small" key={option} variant="filled" label={labelData[option] ? labelData[option] : option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => <TextField {...params} style={{ width: '100%' }} label={label} variant="outlined" placeholder={placeholder} />}
            />
        );
    }
}

export default AutocompleteTaglist;
