import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import { getSelectorUseCase, getSelectorUseCaseId } from 'components/asset-management/utilities';

// Use version without dynamicdata
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import Setup from 'components/data/Setup';
import { mapFileTypesToSubTypes, mapFileTypesToExtentions } from 'components/media-management/utilities';
import { DEFAULT_IMAGE_QUALITY } from '../../../assets/AssetGalleryDialog/index';

import '../styles/main.scss';

/**
 * AssetGalleryInput
 * This is displaying the asset gallery input field.
 * This field can be used to select an image, video or audio file from various sources. It allows editing the image later using the image cropper and other creative tools.
 */
class AssetGalleryInput extends React.Component {
    static propTypes = {
        compactStyling: PropTypes.bool, // Whether to use compact styling
        cropMode: PropTypes.string, // Which crop mode to use. This can be 'sizeBased', 'radioBased' or 'freeform'.
        value: PropTypes.any, // Current selected asset value
        fileType: PropTypes.oneOfType([PropTypes.oneOf(['image', 'video', 'audio', 'pdf', 'png', 'jpg']), PropTypes.arrayOf(PropTypes.string)]), // Allowed file types for selecting assets
        imageFormat: PropTypes.string, // Image format for the cropper. This can be png or jpg. Default is jpg.
        imageQuality: PropTypes.number, // Image quality for the cropper. Default is 0.92. Can be a maximum of 1.
        maxFileSize: PropTypes.number, // Maximum file size allowed for uploading assets. Default is 0, which means no limit. This is in KB.
        sourceData: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]), // Data source for the asset gallery. In case this is an array of objects, the objects should have a 'value' and 'label' property.
        onMutation: PropTypes.func, // Callback function when the asset gallery value is mutated
        onClose: PropTypes.func, // Callback function when the asset gallery dialog is closed
        readOnly: PropTypes.bool, // Whether the component is read-only. If it's read only, you can't select new assets.
        useCropper: PropTypes.bool, // Whether to use the image cropper when selecting an image
        userCanCrop: PropTypes.bool, // Whether the user can crop the selected image
        ratios: PropTypes.array, // Aspect ratios available in the cropper. An example: ['16:9', '9:16', '5:4', '4:5', '1:1']
        duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Duration of selected media (for videos and audio)
        maximumDuration: PropTypes.number, // Maximum duration allowed for videos
        minimumDuration: PropTypes.number, // Minimum duration allowed for videos
        format: PropTypes.string, // Format of the selected media
        outputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Width of the output media.  Can be 0 to use the aspect ratio of the selected media.
        outputHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Height of the output media. Can be 0 to use the aspect ratio of the selected media.
        maxDisplayWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Maximum width for displaying the selected media
        maxDisplayHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Maximum height for displaying the selected media
        canSkipCropper: PropTypes.bool, // Whether to allow skipping the image cropper. This is only applicable if useCropper is true. If this is true, the user can select an image and skip the cropper.
        canSkipCompressor: PropTypes.bool, // Whether to allow skipping the image compressor. If this is true, the image compressor will no an requirement.
        canRemoveBackground: PropTypes.bool, // Whether to allow removing the background of an image. This is only applicable if useCropper is true. If this is true, the user can select an image and remove the background using AI.
        canRemoveObjects: PropTypes.bool,
        canMagicEdit: PropTypes.bool,
        canOutpaint: PropTypes.bool,
        canReuseImage: PropTypes.bool, //If true the user can reuse images without duplicates ending up in the zip or asset.
        canUseUnsplash: PropTypes.bool, // Whether to allow searching for assets on Unsplash.
        openOnMount: PropTypes.bool, // Whether to open the asset gallery dialog when the component mounts.
        rounded: PropTypes.bool, // Whether to use rounded corners for the asset gallery preview.
        ignoreFilterExpired: PropTypes.bool, // Whether to ignore expired filters in the asset gallery
        multiple: PropTypes.bool, // Wether we can upload multiple or just one file
        useParentValue: PropTypes.bool, // If true, only listen to the parent for the value (default is false, which means this components also updates its own state after an image is uploaded). This prop can by handy if you want to do error handling in the parent (otherwise, even if it has errors in the parent, it will still show the asset)
        selectorsProps: PropTypes.object, // Props to pass to each of the selectors.
        fixedHeightPaperScrollPaper: PropTypes.bool, // Prevents scrolling of the dialog content.
        fullWidth: PropTypes.bool, // If true, the dialog stretches to maxWidth.
        className: PropTypes.string, // Class name to add to the asset gallery dialog.
        canUpload: PropTypes.bool, // Whether to allow uploading assets this is the same as canUseUpload but they work in tandem.
        canUseUpload: PropTypes.bool, // Whether to allow uploading assets, this is the same as canUpload but they work in tandem.
        canUseContentSpace: PropTypes.bool, // Whether to allow selecting assets from the content space.
        canUseMediaManagement: PropTypes.bool, // Whether to allow selecting assets from the media management.
        canUseAiContent: PropTypes.bool, // Whether to allow selecting assets from the AI content selector.
        canUseImageToVideo: PropTypes.bool, // Whether to allow generating a video from an image.
        selectors: PropTypes.array, // List of AssetGalleryDialogSidebarSelector type. This is used to add custom selectors to the asset gallery dialog else it will use the default selectors.
        contentSpaceCollectionQuery: PropTypes.string, // Query to open a specific ContentSpace selection
        collectionQuery: PropTypes.string, // Query top open a specific AssetManagement collection
        children: PropTypes.oneOfType([
            PropTypes.func, // Function type for children
            PropTypes.node // React node type (JSX, strings, numbers, etc.)
        ]), // Children to render in the asset gallery dialog.,
        initComponentKey: PropTypes.string // If this is true the asset gallery is opened and the compressor is shown.
    };

    static defaultProps = {
        compactStyling: false,
        value: {},
        fileType: 'image',
        imageQuality: DEFAULT_IMAGE_QUALITY,
        sourceData: [],
        multiple: true,
        useCropper: false,
        userCanCrop: true,
        readOnly: false,
        canSkipCropper: false,
        canSkipCompressor: false,
        canReuseImage: false,
        duration: null,
        minimumDuration: null,
        maximumDuration: null,
        format: '',
        outputWidth: 0,
        outputHeight: 0,
        maxOutputWidth: 0,
        maxOutputHeight: 0,
        maxDisplayWidth: 0,
        maxDisplayHeight: 0,
        onMutation: () => {},
        onClose: () => {},
        openOnMount: false,
        rounded: false,
        ignoreFilterExpired: false, // Set this prop to true if the filterExpired setting should remain unchanged.
        useParentValue: false,
        selectors: [],
        initComponentKey: null
    };

    constructor(props) {
        super(props);

        const {
            openOnMount,
            value,
            canUseUnsplash,
            canUseContentSpace,
            canUseAprimo,
            canUseMediaManagement,
            canRemoveBackground,
            canMagicEdit,
            canRemoveObjects,
            canUseDepthToImage,
            canUseImageEnhancer,
            fileType,
            contentSpaceCollectionQuery,
            collectionQuery,
            canOutpaint
        } = props;
        this.state = {
            assetGalleryDialogOpen: openOnMount,
            value: this.parseValue(value) || {},
            status: 'gallery',
            canUse: {
                canUseUnsplash: this.getCanUse(canUseUnsplash, 'assetsUnsplash'),
                canUseContentSpace: this.getCanUse(canUseContentSpace, 'assetLibrary') | this.getCanUse(canUseContentSpace, 'mediaManagement'),
                canUseAprimo: this.getCanUse(canUseAprimo, 'assetsAprimo'),
                canRemoveBackground: this.getCanUse(canRemoveBackground, 'AI'),
                canMagicEdit: this.getCanUse(canMagicEdit, 'AI'),
                canRemoveObjects: this.getCanUse(canRemoveObjects, 'AI'),
                canUseDepthToImage: this.getCanUse(canUseDepthToImage, 'AI'),
                canUseImageEnhancer: this.getCanUse(canUseImageEnhancer, 'AI'),
                canOutpaint: this.getCanUse(canOutpaint, 'AI')
            },
            selectorsProps: this.getSelectorsProps(canUseContentSpace, canUseMediaManagement, fileType, contentSpaceCollectionQuery, collectionQuery)
        };

        // This is the case if the image is set as a background image in the Template Designer
        if (this.props.isBackgroundImage && this.props.model) {
            this.state.value.backgroundModel = this.props.model;
            this.state.value.isBackgroundImage = true;
            this.props.onMutation(this.state.value, 'model');
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.parseValue(this.props.value) });
        }
        if (prevProps.initComponentKey !== this.props.initComponentKey && this.props.initComponentKey) {
            this.setState({ assetGalleryDialogOpen: true, status: 'cropper' });
        }
    };

    /**
     *
     * @param {Object} value Value of the asset gallery input
     * @returns Value with added paramenters if the value is only a string / url
     */
    parseValue(value) {
        const newValue = (() => {
            //Check if there is a
            if (value && typeof value === 'string') {
                return {
                    url: value,
                    openAssetEditor: true,
                    fileType: typeof this.props.fileType === 'object' ? this.props.fileType[0] : this.props.fileType
                };
            } else {
                return value;
            }
        })();

        return newValue;
    }

    /**
     *  Event to open the dialog window
     */
    openAssetGalleryDialog = () => {
        const { userCanCrop, useCropper, readOnly } = this.props;
        if (!readOnly) {
            const status = this.state.value.isCropped && useCropper && userCanCrop ? 'cropper' : 'gallery';
            this.setState({ status, assetGalleryDialogOpen: true });
        }
    };

    /**
     * Event to close the dialog
     */
    closeAssetGalleryDialog = () => {
        this.setState({ assetGalleryDialogOpen: false, initComponentKey: null });
        this.props.onClose && this.props.onClose();
    };

    /**
     * This close the dialog and writes the data to the parent onMutation element.
     */
    onMutation = (data, type = 'model') => {
        const { formFlow, model, useParentValue } = this.props;

        if (type === 'model') {
            this.setState({ assetGalleryDialogOpen: false });
        }
        if (type === 'model') {
            data.assetGalleryInput = true;
        }
        // This is the case if the image is set as a background image in the Template Designer
        if (this.props.isBackgroundImage && this.props.model) {
            data.isBackgroundImage = true;
            data.backgroundModel = this.props.model;
        }
        this.setState({ value: useParentValue ? this.props.value : data }, () => {
            if (formFlow) {
                this.props.onChange(model, data);
            } else {
                this.props.onMutation(data, type);
            }
        });
    };

    /**
     * Change the active status
     */
    changeStatus = (status) => {
        this.setState({ status });
    };

    /**
     * Remove a specific value
     */
    removeValue = (event) => {
        const { onMutation, formFlow, onChange, model } = this.props;
        this.setState({ value: {} }, () => {
            if (formFlow) {
                onChange(model, {});
            } else {
                onMutation({});
            }
        });
        event && event.stopPropagation();
    };

    // Check if the user can use a specific selector feature
    getCanUse = (canUse, model) => {
        // For aprimo, if we have the module, we always return true
        if (model === 'assetsAprimo' && Setup.hasModule(model)) return true;

        if (canUse !== undefined) {
            return canUse;
        }
        return Setup.hasModule(model);
    };

    // For new style selectors, prepare the props to pass to the selector.
    getSelectorsProps = (canUseContentSpace, canUseMediaManagement, fileType, contentSpaceCollectionQuery, collectionQuery) => {
        const selectorsProps = {};

        // Prepare the selector props for the MediaManagement selector if needed.
        if (Setup.hasModule('mediaManagement')) {
            if (canUseMediaManagement === false) return selectorsProps;
            if (canUseContentSpace === false) return selectorsProps;
            // Map a given fileTye to the extensions that are allowed in MediaManagement.
            const extensions = mapFileTypesToExtentions(fileType);
            const objectName = getSelectorUseCase();
            const objectId = objectName ? getSelectorUseCaseId(objectName) : null;

            selectorsProps.mediaProps = {
                subType: mapFileTypesToSubTypes(fileType),
                dataFilters: extensions.length ? { 'files.0.extension': extensions } : {},
                collectionQuery: collectionQuery ? collectionQuery : contentSpaceCollectionQuery,
                objectName,
                objectId
            };

            return selectorsProps;
        }
    };

    renderInput = () => {
        const { value } = this.state;
        const { maxDisplayWidth, maxDisplayHeight, rounded, readOnly, fileType, children } = this.props;

        let fileTypesText = fileType;
        if (Array.isArray(fileType)) {
            fileTypesText = fileType.join(', ');
        }
        const characters = ['a', 'i', 'o', 'u', 'e'];
        let prefix = characters.some((x) => x === fileTypesText.charAt(0)) ? 'an' : 'a';
        if (readOnly) {
            prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
        }
        fileTypesText = prefix + ' ' + fileTypesText;

        if (children) {
            return children({ onClickOpen: this.openAssetGalleryDialog, onClickRemove: this.removeValue, value });
        }

        return (
            <div className="asset-gallery-input__asset-wrapper">
                {!value.fileType && rounded && (
                    <React.Fragment>
                        <div
                            className={classNames('asset-gallery-input__preview-view', 'asset-gallery-input__preview-view--rounded')}
                            onClick={this.openAssetGalleryDialog}>
                            <div className="asset-gallery-input__preview-view__icon">
                                <Icon size="large">add_a_photo</Icon>
                            </div>
                        </div>
                        <div className="asset-gallery-input__preview-view__actions">
                            {!readOnly && (
                                <div className="asset-gallery-input__preview-view__actions__upload-image" onClick={this.openAssetGalleryDialog}>
                                    Upload image
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
                {!value.fileType && !rounded && (
                    <div className="asset-gallery-input__preview-view" onClick={this.openAssetGalleryDialog}>
                        {!readOnly && (
                            <div className="asset-gallery-input__preview-view__icon">
                                <Icon size="large">add</Icon>
                            </div>
                        )}
                        <div className="asset-gallery-input__preview-view__label">{readOnly ? fileTypesText : `Click to add ${fileTypesText}`}</div>
                    </div>
                )}
                {value.fileType === 'image' && !rounded && (
                    <div className="asset-gallery-input__image-wrapper" onClick={this.openAssetGalleryDialog}>
                        {!readOnly && (
                            <React.Fragment>
                                <div className="asset-gallery-input__edit-button" onClick={this.openAssetGalleryDialog}>
                                    <Icon fontSize="small">edit</Icon>
                                </div>
                                <div className="asset-gallery-input__remove-button" onClick={this.removeValue}>
                                    <Icon fontSize="small">delete</Icon>
                                </div>
                            </React.Fragment>
                        )}
                        <img
                            src={value.url}
                            style={{
                                maxHeight: maxDisplayHeight > 0 ? maxDisplayHeight : undefined,
                                maxWidth: maxDisplayWidth > 0 ? maxDisplayWidth : undefined
                            }}
                        />
                    </div>
                )}
                {value.fileType === 'image' && rounded && (
                    <React.Fragment>
                        <div className={classNames('asset-gallery-input__image-wrapper', 'asset-gallery-input__image-wrapper--rounded')}>
                            <img
                                src={value.url}
                                style={{
                                    maxHeight: maxDisplayHeight > 0 ? maxDisplayHeight : undefined,
                                    maxWidth: maxDisplayWidth > 0 ? maxDisplayWidth : undefined
                                }}
                                onClick={this.openAssetGalleryDialog}
                            />
                        </div>
                        {!readOnly && (
                            <div className="asset-gallery-input__image-wrapper__actions">
                                <div className="asset-gallery-input__image-wrapper__actions__upload-asset" onClick={this.openAssetGalleryDialog}>
                                    {value.url ? 'Edit picture' : 'Upload image'}
                                </div>
                                <div className="asset-gallery-input__image-wrapper__actions__delete-asset" onClick={this.removeValue}>
                                    Delete
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {value.fileType === 'video' && !rounded && (
                    <div className="asset-gallery-input__video-wrapper">
                        {!readOnly && (
                            <React.Fragment>
                                <div className="asset-gallery-input__edit-button" onClick={this.openAssetGalleryDialog}>
                                    <Icon fontSize="small">edit</Icon>
                                </div>
                                <div className="asset-gallery-input__remove-button" onClick={this.removeValue}>
                                    <Icon fontSize="small">delete</Icon>
                                </div>
                            </React.Fragment>
                        )}
                        <video id="asset-gallery-video" className="asset-gallery-input__video-wrapper__video" controls src={value.url}></video>
                    </div>
                )}
                {value.fileType === 'audio' && !rounded && (
                    <div className="asset-gallery-input__audio-wrapper">
                        {!readOnly && (
                            <React.Fragment>
                                <div className="asset-gallery-input__edit-button" onClick={this.openAssetGalleryDialog}>
                                    <Icon fontSize="small">edit</Icon>
                                </div>
                                <div className="asset-gallery-input__remove-button" onClick={this.removeValue}>
                                    <Icon fontSize="small">delete</Icon>
                                </div>
                            </React.Fragment>
                        )}
                        <audio id="asset-gallery-audio" className="asset-gallery-input__audio-wrapper__audio" controls src={value.url}></audio>
                    </div>
                )}
                {value.fileType === 'application' && !rounded && (
                    <div className="asset-gallery-input__application-wrapper">
                        {!readOnly && (
                            <React.Fragment>
                                <div className="asset-gallery-input__edit-button" onClick={this.openAssetGalleryDialog}>
                                    <Icon fontSize="small">edit</Icon>
                                </div>
                                <div className="asset-gallery-input__remove-button" onClick={this.removeValue}>
                                    <Icon fontSize="small">delete</Icon>
                                </div>
                            </React.Fragment>
                        )}
                        <p className="asset-gallery-input__application-wrapper__title">{value.title}</p>
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { assetGalleryDialogOpen, status, selectorsProps, canUse } = this.state;
        const { rounded, compactStyling, readOnly, initComponentKey } = this.props;

        // If the value is an array, we use the props value this is data from the uploader.
        // Otherwise we use the state value this is data possibly from value being a string and thus enhanced with metadata in the state.
        const newValue = (() => {
            //If props value is an array thats the value to pick.
            if (Array.isArray(this.state.value)) {
                return this.props.value;
            } else return this.state.value;
        })();

        const newProps = {
            ...this.props,
            ...canUse,
            value: newValue,
            open: assetGalleryDialogOpen,
            status,
            selectorsProps,
            openAssetEditor: !!initComponentKey,
            initComponentKey,
            onClose: this.closeAssetGalleryDialog,
            onMutation: this.onMutation,
            changeStatus: this.changeStatus
        };

        // Make sure outputHeight and outputWidth are numbers
        newProps.outputHeight = parseInt(newProps.outputHeight);
        newProps.outputWidth = parseInt(newProps.outputWidth);
        if (isNaN(newProps.outputHeight)) newProps.outputHeight = 0;
        if (isNaN(newProps.outputWidth)) newProps.outputWidth = 0;
        // Make sure outputHeight and outputWidth are numbers
        newProps.maxOutputHeight = parseInt(newProps.maxOutputHeight);
        newProps.maxOutputWidth = parseInt(newProps.maxOutputWidth);
        if (isNaN(newProps.outputHeight)) newProps.outputHeight = 0;
        if (isNaN(newProps.outputWidth)) newProps.outputWidth = 0;

        return (
            <div
                className={classNames('asset-gallery-input', {
                    'asset-gallery-input--compact': compactStyling,
                    'asset-gallery-input--rounded': rounded,
                    'asset-gallery-input--read-only': readOnly
                })}>
                {this.renderInput()}

                {
                    // Unmount the AssetGalleryDialog if assetGalleryDialogOpen is false.
                    assetGalleryDialogOpen && <AssetGalleryDialog {...newProps} />
                }
            </div>
        );
    }
}

export default AssetGalleryInput;
