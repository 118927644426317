import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

export class ColorSelectorCompact extends Component {
    state = {
        displayColorPicker: false
    };

    static propTypes = {
        /** Array of colors to display as circles */
        colors: PropTypes.arrayOf(PropTypes.string),
        /** Function which gets called when the value has changed */
        onChange: PropTypes.func,
        /** Current value of this selector */
        value: PropTypes.any,
        useAlpha: PropTypes.bool
    };

    static defaultProps = {
        onChange: () => {},
        value: ''
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    render() {
        const { onChange, value, useAlpha, colors } = this.props;
        const { displayColorPicker } = this.state;

        return (
            <div className="color-selector__compact">
                <div className="color-selector__compact__swatch" onClick={this.handleClick}>
                    <div className="color-selector__compact__checkers"></div>
                    <div className="color-selector__compact__color" style={{ backgroundColor: value.points[0].color.hex }} />
                </div>
                {displayColorPicker ? (
                    <div className="color-selector__compact__popover">
                        <div className="color-selector__compact__cover" onClick={this.handleClose} />
                        <SketchPicker presetColors={colors} color={value.points[0].color.hex} onChange={onChange} disableAlpha={!useAlpha} />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ColorSelectorCompact;
