import React, { Component } from 'react';
import DataHelpers from 'components/data/DataHelpers';
import Setup from 'components/data/Setup';
import SelectInput from '../../SelectInput';

/**
 * CampaignCategorySelector
 * This component allows you to select the category
 */
export default class CampaignCategorySelector extends Component {
    render() {
        const setup = Setup.get();

        // Get categories
        let categories = DataHelpers.getValue(setup, 'campaigns.categories');
        if (!categories) {
            categories = {};
        }

        categories = {
            '': 'No category',
            ...categories
        };

        return <SelectInput {...this.props} options={categories} />;
    }
}
