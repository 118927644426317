import { v4 as uuidv4 } from 'uuid';
import { CreativeV2, CreativeV2CustomUpload, CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import AssetManagementService from 'components/asset-management/services/asset-management.service';

const createCustomUploadCreative = (customUploadData: any): CreativeV2CustomUpload => {
    const creative: CreativeV2CustomUpload = {
        id: uuidv4(),
        title: customUploadData.title,
        type: 'customUpload',
        data: {
            ...customUploadData,
            width: customUploadData.width || customUploadData.assetData?.width,
            height: customUploadData.height || customUploadData.assetData?.height
        }
    };

    return creative;
};

const createTemplateCreative = async (customUploadData: any): Promise<CreativeV2Template> => {
    const newCreativeData = await AssetManagementService.createAsset({
        type: 'creativeV2',
        title: customUploadData.title,
        data: {
            templateType: customUploadData.subType,
            templateId: customUploadData.data.identifier,
            settings: {
                frames: 1
            },
            ...(!!customUploadData.data.defaultData && { templateInput: customUploadData.data.defaultData })
        }
    });

    const creative: CreativeV2Template = {
        id: uuidv4(),
        title: newCreativeData.data.title,
        type: 'template',
        assetManagerId: newCreativeData.data._id
    };

    return creative;
};

const createCreativeBasedOnCampaignAsset = (customUploadData: any): CreativeV2 => {
    switch (customUploadData.type) {
        case 'template': {
            const creative: CreativeV2Template = {
                id: uuidv4(),
                title: customUploadData.title,
                type: 'template',
                assetManagerId: customUploadData.assetManagerId
            };

            return creative;
        }
        default:
            return createCustomUploadCreative(customUploadData);
    }
};

export { createCustomUploadCreative, createTemplateCreative, createCreativeBasedOnCampaignAsset };
