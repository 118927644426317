import React from 'react';
import '../styles/add-item.scss';
import Icon from 'components/ui-components-v2/Icon';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';

type Props = {
    openDialog: () => void;
    classes?: {
        root?: string;
    };
};

const CreativeSelectorAddItem = ({ openDialog, classes }: Props) => {
    return (
        <div className={classNames('creative-selector-add-item', classes?.root)} onClick={openDialog}>
            <div className="creative-selector-add-item__icon-container">
                <Icon className="creative-selector-add-item__icon-container__icon">add</Icon>
            </div>
            <Typography variant="body2" color="textPrimary">
                {Translation.get('input.creativeSelector.addAsset', 'common')}{' '}
            </Typography>
        </div>
    );
};

export { CreativeSelectorAddItem };
