import { convertHexToRgb, convertRgbToHex, getRgbValueFromString } from 'helpers/colors';

/**
 * Converts the color to an object needed for the color picker
 * @param {string} color - Color string
 * @returns {object} - An object with the type, rotation and points.
 */
const getColorPickerObject = (color) => {
    if (color === 'transparent') {
        return {
            type: 'transparent',
            rotation: 0,
            points: [
                {
                    location: 0,
                    color: {
                        hex: '#ffffff',
                        rgb: {
                            r: 0,
                            g: 0,
                            b: 0,
                            a: 1
                        }
                    }
                },
                {
                    location: 1,
                    color: {
                        hex: '#000000',
                        rgb: {
                            r: 0,
                            g: 0,
                            b: 0,
                            a: 1
                        }
                    }
                }
            ]
        };
    }

    // If the color starts with an '#', then it is an HEX color.
    if (color.startsWith('#')) {
        const rgb = convertHexToRgb(color);

        return {
            type: 'solid',
            rotation: 0,
            points: [
                {
                    location: 0,
                    color: {
                        hex: color,
                        rgb: rgb
                    }
                },
                {
                    location: 1,
                    color: {
                        hex: '#000000',
                        rgb: {
                            r: 0,
                            g: 0,
                            b: 0,
                            a: 1
                        }
                    }
                }
            ]
        };
    }

    // If the color starts with an 'rgb', then it is an RGB or RGBA color.
    if (color.startsWith('rgb')) {
        const rgbArray = getRgbValueFromString(color) || [0, 0, 0, 1];
        const hex = convertRgbToHex(rgbArray) || '#000000ff';

        const rgb = {
            r: rgbArray[0],
            g: rgbArray[1],
            b: rgbArray[2],
            a: rgbArray[3] !== undefined ? rgbArray[3] : undefined
        };

        return {
            type: 'solid',
            rotation: 0,
            points: [
                {
                    location: 0,
                    color: {
                        hex,
                        rgb
                    }
                },
                {
                    location: 1,
                    color: {
                        hex: '#000000',
                        rgb: {
                            r: 0,
                            g: 0,
                            b: 0,
                            a: 1
                        }
                    }
                }
            ]
        };
    }

    const gradientRegex = /^(?:repeating-)?(linear|radial|conic)/;
    const colorRegex =
        /((?:\b(?:linear|radial)-gradient\s*\((?:(?:\s*(?:to\s+\w+|[\d.]+%|top|bottom|left|right)\s*,)?\s*)+)|(?:#(?:[0-9a-fA-F]{3}){1,2}|rgba?\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}(?:\s*,\s*(?:\d(?:\.\d+)?|\.\d+))?\s*\)))\s*([\d.]+%)/g;

    const match = gradientRegex.exec(color);
    const gradientType = (() => {
        if (match && match[0]) {
            return match[0];
        }

        return 'linear';
    })();

    const colors = [];
    let colorMatch;

    while ((colorMatch = colorRegex.exec(color)) !== null) {
        colors.push({ color: colorMatch[1], position: colorMatch[2] });
    }

    const rotationRegex = /(\d*\.?\d+)(?:\s*(deg|rad|turn))?|to\s+(top|right|bottom|left|top\s+left|top\s+right|bottom\s+left|bottom\s+right)/;
    const rotationMatch = color.match(rotationRegex); // Match the gradient direction

    let rotation = 0;

    if (rotationMatch) {
        const value = parseFloat(rotationMatch[1]);
        const unit = rotationMatch[2];
        const direction = rotationMatch[0] || rotationMatch[3];

        if (!isNaN(value)) {
            if (unit === 'deg') {
                rotation = value;
            } else if (unit === 'rad') {
                rotation = (value * 180) / Math.PI;
            } else if (unit === 'turn') {
                rotation = value * 360;
            }
        } else if (direction) {
            const angleMap = {
                'to top': 0,
                'to top right': 45,
                'to right': 90,
                'to bottom right': 135,
                'to bottom': 180,
                'to bottom left': 225,
                'to left': 270,
                'to top left': 315
            };

            rotation = angleMap[direction];
        }
    }

    // Create a start object so this can be modified in this function.
    return {
        type: gradientType,
        rotation: Number(rotation),
        points: colors.map(({ color, position }) => {
            const hex = (() => {
                if (color.startsWith('#')) {
                    return color;
                }

                return convertRgbToHex(getRgbValueFromString(color) || [0, 0, 0, 1]) || '#000000ff';
            })();

            const rgb = (() => {
                if (color.startsWith('#')) {
                    return convertHexToRgb(color);
                }

                const rgbArray = getRgbValueFromString(color) || [0, 0, 0, 1];
                return {
                    r: rgbArray[0],
                    g: rgbArray[1],
                    b: rgbArray[2],
                    a: rgbArray[3] !== undefined ? rgbArray[3] : 1
                };
            })();

            return {
                location: Number(position.match(/\d+/)[0]) / 100,
                color: {
                    hex,
                    rgb
                }
            };
        })
    };
};

export default getColorPickerObject;
