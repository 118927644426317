import React, { Component } from 'react';
import DataHelpers from 'components/data/DataHelpers';
import Setup from 'components/data/Setup';
import AutoComplete from '../../AutoComplete';

/**
 * CampaignTags
 * This allows you to fill in the tags for a campaign
 */
export default class CampaignTags extends Component {
    render() {
        const setup = Setup.get();

        // Get categories
        let options = DataHelpers.getValue(setup, 'campaigns.tags');

        // Use the original options as labelData
        const labelData = { ...options };

        if (!options) {
            options = [];
        }
        if (!Array.isArray(options)) {
            options = Object.keys(options).map((option) => option);
        }

        return <AutoComplete {...this.props} options={options} labelData={labelData} inputType="taglist" />;
    }
}
