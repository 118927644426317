import React from 'react';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { isTemplateCreative } from 'components/creatives-v2/guards/creative-type-guards';
import Loader from 'components/ui-components/Loader';
import CreativeSelectorItem from './item';
import { CreativeSelectorAddItem } from './add-item';

interface Props {
    creatives: CreativeV2Enriched[];
    openAssetGalleryDialog: () => void;
    onChange: (creatives: CreativeV2Enriched[]) => void;
    editCreative: (creative: CreativeV2Enriched) => void;
    loaded: boolean;
}

const MultiCreativeSelector = ({ creatives, openAssetGalleryDialog, onChange, editCreative, loaded }: Props) => {
    const removeCreative = async (creative: CreativeV2Enriched) => {
        if (isTemplateCreative(creative)) {
            await AssetManagementService.deleteAsset(creative.assetManagerId, true);
        }

        const newCreatives = creatives.filter((item) => item.id !== creative.id);

        onChange(newCreatives);
        SnackbarUtils.success('Creative removed');
    };

    return (
        <>
            {!loaded && <Loader />}
            {loaded && (
                <>
                    {creatives.map((creative, index) => {
                        return <CreativeSelectorItem key={index} creative={creative} editCreative={editCreative} removeCreative={removeCreative} />;
                    })}
                    <CreativeSelectorAddItem
                        openDialog={() => openAssetGalleryDialog()}
                        classes={{ root: creatives.length ? 'creative-selector-add-item--space' : undefined }}
                    />
                </>
            )}
        </>
    );
};

export { MultiCreativeSelector };
