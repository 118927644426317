import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';
import EditorData from 'components/editor-data/EditorData';
import { AutocompleteTaglist } from './autocomplete-taglist';

/**
 * Autocomplete function
 * This allows you to create a list of tags using autocomplete.
 */
export class Autocomplete extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        onMutation: PropTypes.func,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        /* Predefined options to choose from */
        options: PropTypes.arrayOf(PropTypes.string),
        defaultValue: PropTypes.array,
        /* The type of autocomplete (e.g. taglist) */
        inputType: PropTypes.string,
        /* If this is true, the user can input arbitrary values (not only predefined options) */
        freeSolo: PropTypes.bool,
        /* If there are no labels (only values), you can give an object with the labels --> {'value1': 'label1', 'value2': 'label2'}  */
        labelData: PropTypes.object,
        /* Close the dropdown after selecting an item */
        disableCloseOnSelect: PropTypes.bool
    };

    static defaultProps = {
        onMutation: () => {},
        options: [],
        defaultValue: [],
        inputType: 'taglist',
        freeSolo: true,
        value: [],
        optionsFromModel: null,
        placeholder: '',
        labelData: {},
        disableCloseOnSelect: false
    };

    constructor(props) {
        super(props);

        let options = props.options;

        if (props.optionsFromModel) {
            options = this.getOptionsFromModel(props.optionsFromModel);
        }

        this.state = {
            value: props.value,
            options
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value === prevProps.value) {
            this.setState({ value: this.props.value });
        }
        if (!isEqual(prevProps.options, this.props.options) && isEqual(this.state.options, prevProps.options)) {
            let options = this.props.options;

            if (this.props.optionsFromModel) {
                options = this.getOptionsFromModel(this.props.optionsFromModel);
            }
            this.setState({ options });
        }
    }

    /**
     * Returns the value of the model that is given as a prop. These will then be the options to select from in the autocomplete list
     * @param {*} model The model in redux
     */
    getOptionsFromModel = (model) => {
        const options = EditorData.getValueFromModel(model);

        if (options && Array.isArray(options) && options.length > 0) {
            return options;
        } else {
            console.error('Make sure that the model (optionsFromModel) returns an valid array');
            return [];
        }
    };

    /**
     * On change item
     * Sets the parent using onMutation
     */
    onChange = (_e, value) => {
        this.setState({ value });
        this.props.onMutation(value);
    };

    render = () => {
        const { value, options } = this.state;
        const { inputType, placeholder, defaultValue, freeSolo, labelData, disableCloseOnSelect } = this.props;

        // Taglist
        if (inputType === 'taglist') {
            let newOptions = [];
            let newLabelData = {};

            if (typeof options === 'object' && !Array.isArray(options)) {
                // Use the original options as labelData
                newLabelData = { ...options };
                newOptions = Object.keys(options);
                if (!newOptions) {
                    newOptions = [];
                }
            } else {
                newOptions = options;
                newLabelData = labelData;
            }

            return (
                <AutocompleteTaglist
                    value={value}
                    onChange={this.onChange}
                    options={newOptions}
                    defaultValue={defaultValue}
                    freeSolo={freeSolo}
                    placeholder={placeholder}
                    labelData={newLabelData}
                    disableCloseOnSelect={disableCloseOnSelect}
                />
            );
        } else {
            return <React.Fragment></React.Fragment>;
        }
    };
}

export default Autocomplete;
