import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Setup from 'components/data/Setup';

/**
 * ChannelSelector
 * This component allows you to activate one or more channels.
 */
export default class CampaignChannelSelector extends Component {
    static propTypes = {
        value: PropTypes.any,
        options: PropTypes.object,
        singleSelect: PropTypes.bool,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        view: PropTypes.string
    };

    static defaultProps = {
        value: [],
        singleSelect: false,
        options: Setup.get('channels') ? Setup.get('channels') : [],
        onMutation: () => {},
        view: 'default'
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : []
        };
    }

    componentDidUpdate = (prevProps, prevState) => {};

    /**
     * Toggle selectable option
     */
    updateValues = (key, checked) => {
        const { singleSelect } = this.props;

        // Single select
        if (singleSelect) {
            this.setState({ value: [key] }, this.updateItem);
        }
        // Multi select
        else {
            let newValue = [];
            if (checked) {
                newValue = [...this.state.value, key];
            } else {
                newValue = this.state.value.filter((x) => x !== key);
            }
            this.setState({ value: newValue }, this.updateItem);
        }
    };

    /**
     * Update the active value in the datasource
     */
    updateItem = () => {
        const { onMutation } = this.props;
        onMutation(this.state.value);
    };

    /**
     * Render an individual item
     */
    renderSelectable = (itemKey, index) => {
        const { options, view } = this.props;
        const { value } = this.state;

        let isChecked = false;
        if (Array.isArray(value) && value.includes(itemKey)) {
            isChecked = true;
        }

        if (view === 'list') {
            return (
                <div
                    onClick={() => this.updateValues(itemKey, !isChecked)}
                    key={index}
                    className={'campaign-channel-selector__list__item ' + (isChecked ? 'campaign-channel-selector__list__item--selected' : '')}>
                    <div className="campaign-channel-selector__list__item__title">{options[itemKey]}</div>
                </div>
            );
        } else {
            return (
                <div
                    onClick={() => this.updateValues(itemKey, !isChecked)}
                    key={index}
                    className={'campaign-channel-selector__item ' + (isChecked ? 'campaign-channel-selector__item--selected' : '')}>
                    <div>
                        <div className={'campaign-channel-selector__item__image channel-selector__item__image--' + itemKey}></div>
                    </div>
                    <div className="campaign-channel-selector__item__title">{options[itemKey]}</div>
                </div>
            );
        }
    };

    render() {
        const { options } = this.props;
        return <div className="campaign-channel-selector">{Object.keys(options).map((itemKey, index) => this.renderSelectable(itemKey, index))}</div>;
    }
}
